<template>
  <t-modal
    :name="id"
    :variant="`confirmation`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="!isLoading"
    :clickToClose="!isLoading"
    ref="popup"
  >
    <div class="relative ">
      <loading :active="isBuyerListLoading" :is-full-page="false" />

      <section class="px-4 text-2xl font-extrabold text-center mt-8">
        Create Purchase Order
      </section>

      <section class="px-4 py-2 ">
        <AppInput
          v-model="form.buyer"
          type="richselect"
          name="Buyer"
          label="Buyer"
          rules="required"
          :isSearch="true"
          placeholder="Select a buyer"
          value-attribute="value"
          text-attribute="text"
          :delaySearchTime="1000"
          :fetch-options="getBuyerList"
          @input="getSelectedBuyer"
        />
      </section>
      <section class="px-4 py-2 ">
        <AppInput
          v-model="form.number_of_vehicles"
          rules="required"
          label="Number of Vehicles"
          type="number"
          :name="`Number of Vehicles`"
        />
      </section>
      <section class="px-4 py-2 mb-5 ">
        <AppInput
          name="payment method"
          label="Payment Method"
          type="richselect"
          placeholder="Select payment method"
          v-model="form.payment_method_choice"
          :options="[
            { text: 'Cash', value: 'cash' },
            { text: 'Bank', value: 'bank' },
            { text: 'Crypto', value: 'crypto' },
          ]"
          :hide-search-box="true"
        />
      </section>
      <section
        class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
      >
        <AppButton variant="secondary" :class="`w-1/2`" @click="closeModal()">
          Cancel
        </AppButton>

        <AppButton
          :class="`w-1/2`"
          :isLoading="isLoading"
          @click="placeOrder()"
        >
          Purchase
        </AppButton>
      </section>
    </div>
  </t-modal>
</template>

<script>
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import { xMan } from '@/utils'
export default {
  name: 'PurchaseOrderCreationAction',
  components: {},

  props: {
    id: {
      type: String,
      required: true,
    },

    marketplaceId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    form: {
      buyer: null,
      number_of_vehicles: null,
      marketplace_fleet: null,
      payment_method_choice: null,
    },
  }),
  watch: {
    marketplaceId: {
      deep: false,
      immediate: true,
      handler(val) {
        this.form.marketplace_fleet = val
      },
    },
  },

  methods: {
    closeModal() {
      this.$modal.hide(`${this.id}`)
      this.clearOrderData()
    },
    async getBuyerList(query) {
      const { data } = await this.$http.get(
        `${MarketplaceConfig.api.marketplaceAllBuyerList()}?search=${query}`
      )
      let list = []
      if (data?.data.length > 0) {
        data.data.forEach(function(row) {
          list.push({
            text: `${row.full_name} (${row.email})`,
            value: row.id,
          })
        })
      }

      return { results: list }
    },
    getSelectedBuyer(id) {
      this.form.buyer = id
    },
    async placeOrder() {
      this.isLoading = true
      const url = MarketplaceConfig.api.marketplacePlaceOrder()
      const data = new xMan(this.form).toFormData()
      await this.$http
        .post(url, data)
        .then(({ data }) => {
          this.$emit('refresh-page')
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: `${data.message}`,
            },
            3000
          )
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Failed',
              text: `${err.response}`,
            },
            5000
          )
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
          this.clearOrderData()
        })
    },
    clearOrderData() {
      console.log('clearOrderData')
      this.form.buyer = null
      this.form.number_of_vehicles = null
      this.form.payment_method_choice = null
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
}
</style>
